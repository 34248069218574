import React, { Component } from 'react';
import './CSS/AcompanheSeuProcesso.css';

import Footer from '../Components/Footer';
import Header from '../Components/Header';

export class AcompanheSeuProcesso extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
             aspSub: 4
        }
    }
    

    componentDidMount () {
        this.openAspSub(4);
    }
    
    openAspSub = (subMenu) => {
        const jeSub = document.querySelectorAll('#asp-je>li');
        const trfSub = document.querySelectorAll('#asp-trf>li');
        const tsSub = document.querySelectorAll('#asp-ts>li');
        const tfeuSub = document.querySelectorAll('#asp-tfeu>li');

        if (this.state.aspSub === subMenu) {
            // CLOSE ALL SUBMENUS
            const subs = [jeSub, trfSub, tsSub, tfeuSub];
            subs.forEach(sub => {
                sub.forEach(s => {
                    const sId = document.getElementById(s.id);
                    sId.style.display = 'none';
                });
            });

            this.setState({
                aspSub: 4
            });

        } else {
            // CLOSE ALL SUBMENUS
            const subs = [jeSub, trfSub, tsSub, tfeuSub];
            subs.forEach(sub => {
                sub.forEach(s => {
                    const sId = document.getElementById(s.id);
                    sId.style.display = 'none';
                });
            });

            if (subMenu === 0) {
                jeSub.forEach(s => {
                    const sId = document.getElementById(s.id);
                    sId.style.display = 'block';
                });
    
                this.setState({
                    aspSub: 0
                });
    
            } else if (subMenu === 1) {
                trfSub.forEach(s => {
                    const sId = document.getElementById(s.id);
                    sId.style.display = 'block';
                });
    
                this.setState({
                    aspSub: 1
                });
    
            } else if (subMenu === 2) {
                tsSub.forEach(s => {
                    const sId = document.getElementById(s.id);
                    sId.style.display = 'block';
                });
    
                this.setState({
                    aspSub: 2
                });
    
            } else if (subMenu === 3) {
                tfeuSub.forEach(s => {
                    const sId = document.getElementById(s.id);
                    sId.style.display = 'block';
                });
    
                this.setState({
                    aspSub: 3
                });
    
            }
        }
    }

    render() {
        return (
            <div className='bg'>

                <Header/>

                <div className='asp-holder' >
                    <div>
                        <p>ACOMPANHE SEU PROCESSO AQUI</p>
                        <div>
                            <ul id='asp-je'>
                                <div><button className='asp-btn' onClick={() => { this.openAspSub(0) }}>JUSTIÇA ESTADUAL</button></div>
                                <li id='je-0'><a href='https://www.tjac.jus.br/' target='_blank' rel='noopener noreferrer'>ACRE</a></li>
                                <li id='je-1'><a href='https://www.tjal.jus.br/' target='_blank' rel='noopener noreferrer'>ALAGOAS</a></li>
                                <li id='je-2'><a href='https://www.tjap.jus.br/' target='_blank' rel='noopener noreferrer'>AMAPÁ</a></li>
                                <li id='je-3'><a href='https://www.tjam.jus.br/' target='_blank' rel='noopener noreferrer'>AMAZONAS</a></li>
                                <li id='je-4'><a href='https://www.tjba.jus.br/' target='_blank' rel='noopener noreferrer'>BAHIA</a></li>
                                <li id='je-5'><a href='https://www.tjce.jus.br/' target='_blank' rel='noopener noreferrer'>CEARÁ</a></li>
                                <li id='je-6'><a href='https://www.tjdft.jus.br/' target='_blank' rel='noopener noreferrer'>DISTRITO FEDERAL</a></li>
                                <li id='je-7'><a href='https://www.tjes.jus.br/' target='_blank' rel='noopener noreferrer'>ESPÍRITO SANTO</a></li>
                                <li id='je-8'><a href='https://www.tjgo.jus.br/' target='_blank' rel='noopener noreferrer'>GOIÁS</a></li>
                                <li id='je-9'><a href='https://www.tjma.jus.br/' target='_blank' rel='noopener noreferrer'>MARANHÃO</a></li>
                                <li id='je-10'><a href='https://www.tjmt.jus.br/' target='_blank' rel='noopener noreferrer'>MATO GROSSO</a></li>
                                <li id='je-11'><a href='https://www.tjms.jus.br/' target='_blank' rel='noopener noreferrer'>MATO GROSSO DO SUL</a></li>
                                <li id='je-12'><a href='https://www.tjmg.jus.br/' target='_blank' rel='noopener noreferrer'>MINAS GERAIS</a></li>
                                <li id='je-13'><a href='https://www.tjpa.jus.br/' target='_blank' rel='noopener noreferrer'>PARÁ</a></li>
                                <li id='je-14'><a href='https://www.tjpb.jus.br/' target='_blank' rel='noopener noreferrer'>PARAÍBA</a></li>
                                <li id='je-15'><a href='https://www.tjpr.jus.br/' target='_blank' rel='noopener noreferrer'>PARANÁ</a></li>
                                <li id='je-16'><a href='https://www.tjpe.jus.br/' target='_blank' rel='noopener noreferrer'>PERNAMBUCO</a></li>
                                <li id='je-17'><a href='https://www.tjpi.jus.br/' target='_blank' rel='noopener noreferrer'>PIAUÍ</a></li>
                                <li id='je-18'><a href='https://www.tjrj.jus.br/' target='_blank' rel='noopener noreferrer'>RIO DE JANEIRO</a></li>
                                <li id='je-19'><a href='https://www.tjrn.jus.br/' target='_blank' rel='noopener noreferrer'>RIO GRANDE DO NORTE</a></li>
                                <li id='je-20'><a href='https://www.tjrs.jus.br/' target='_blank' rel='noopener noreferrer'>RIO GRANDE DO SUL</a></li>
                                <li id='je-21'><a href='https://www.tjro.jus.br/' target='_blank' rel='noopener noreferrer'>RONDÔNIA</a></li>
                                <li id='je-22'><a href='https://www.tjrr.jus.br/' target='_blank' rel='noopener noreferrer'>RORAIMA</a></li>
                                <li id='je-23'><a href='https://www.tjsc.jus.br/' target='_blank' rel='noopener noreferrer'>SANTA CATARINA</a></li>
                                <li id='je-24'><a href='https://www.tjsp.jus.br/' target='_blank' rel='noopener noreferrer'>SÃO PAULO</a></li>
                                <li id='je-25'><a href='https://www.tjse.jus.br/' target='_blank' rel='noopener noreferrer'>SERGIPE</a></li>
                                <li id='je-26'><a href='https://www.tjto.jus.br/' target='_blank' rel='noopener noreferrer'>TOCANTINS</a></li>
                            </ul>
                        </div>

                        <div>
                            <ul id='asp-tfeu'>
                                <div><button className='asp-btn' onClick={() => { this.openAspSub(3) }}>JUSTIÇA FEDERAL NOS ESTADOS</button></div>
                                <li id='tfeu-0'><a href='https://www.trf1.jus.br/sjac/home/' target='_blank' rel='noopener noreferrer'>ACRE</a></li>
                                <li id='tfeu-1'><a href='https://www.jfal.jus.br/' target='_blank' rel='noopener noreferrer'>ALAGOAS</a></li>
                                <li id='tfeu-2'><a href='https://www.trf1.jus.br/sjap/home/' target='_blank' rel='noopener noreferrer'>AMAPÁ</a></li>
                                <li id='tfeu-3'><a href='https://www.trf1.jus.br/sjam/home/' target='_blank' rel='noopener noreferrer'>AMAZONAS</a></li>
                                <li id='tfeu-4'><a href='https://www.trf1.jus.br/sjba/home/' target='_blank' rel='noopener noreferrer'>BAHIA</a></li>
                                <li id='tfeu-5'><a href='https://www.jfce.jus.br/' target='_blank' rel='noopener noreferrer'>CEARÁ</a></li>
                                <li id='tfeu-6'><a href='https://www.trf1.jus.br/sjdf/home/' target='_blank' rel='noopener noreferrer'>DISTRITO FEDERAL</a></li>
                                <li id='tfeu-7'><a href='https://www.jfes.jus.br/' target='_blank' rel='noopener noreferrer'>ESPÍRITO SANTO</a></li>
                                <li id='tfeu-8'><a href='https://www.trf1.jus.br/sjgo/home/' target='_blank' rel='noopener noreferrer'>GOIÁS</a></li>
                                <li id='tfeu-9'><a href='https://www.trf1.jus.br/sjma/home/' target='_blank' rel='noopener noreferrer'>MARANHÃO</a></li>
                                <li id='tfeu-10'><a href='https://www.trf1.jus.br/sjmt/home/' target='_blank' rel='noopener noreferrer'>MATO GROSSO</a></li>
                                <li id='tfeu-11'><a href='https://www.jfms.jus.br/' target='_blank' rel='noopener noreferrer'>MATO GROSSO DO SUL</a></li>
                                <li id='tfeu-12'><a href='https://sjmg.trf6.jus.br/' target='_blank' rel='noopener noreferrer'>MINAS GERAIS</a></li>
                                <li id='tfeu-13'><a href='https://www.trf1.jus.br/sjpa/home/' target='_blank' rel='noopener noreferrer'>PARÁ</a></li>
                                <li id='tfeu-14'><a href='https://www.jfpb.jus.br/' target='_blank' rel='noopener noreferrer'>PARAÍBA</a></li>
                                <li id='tfeu-15'><a href='https://www.trf4.jus.br/trf4/controlador.php?acao=principal' target='_blank' rel='noopener noreferrer'>PARANÁ</a></li>
                                <li id='tfeu-16'><a href='https://www.jfpe.jus.br/' target='_blank' rel='noopener noreferrer'>PERNAMBUCO</a></li>
                                <li id='tfeu-17'><a href='https://www.trf1.jus.br/sjpi/home/' target='_blank' rel='noopener noreferrer'>PIAUÍ</a></li>
                                <li id='tfeu-18'><a href='https://www.jfrj.jus.br/' target='_blank' rel='noopener noreferrer'>RIO DE JANEIRO</a></li>
                                <li id='tfeu-19'><a href='https://www.jfrn.jus.br/' target='_blank' rel='noopener noreferrer'>RIO GRANDE DO NORTE</a></li>
                                <li id='tfeu-20'><a href='https://www.trf4.jus.br/trf4/controlador.php?acao=principal' target='_blank' rel='noopener noreferrer'>RIO GRANDE DO SUL</a></li>
                                <li id='tfeu-21'><a href='https://www.trf1.jus.br/sjro/home/' target='_blank' rel='noopener noreferrer'>RONDÔNIA</a></li>
                                <li id='tfeu-22'><a href='https://www.trf1.jus.br/sjrr/home/' target='_blank' rel='noopener noreferrer'>RORAIMA</a></li>
                                <li id='tfeu-23'><a href='https://www.trf4.jus.br/trf4/controlador.php?acao=principal&' target='_blank' rel='noopener noreferrer'>SANTA CATARINA</a></li>
                                <li id='tfeu-24'><a href='https://www.jfsp.jus.br/' target='_blank' rel='noopener noreferrer'>SÃO PAULO</a></li>
                                <li id='tfeu-25'><a href='https://www.jfse.jus.br/' target='_blank' rel='noopener noreferrer'>SERGIPE</a></li>
                                <li id='tfeu-26'><a href='https://www.trf1.jus.br/sjto/home/' target='_blank' rel='noopener noreferrer'>TOCANTINS</a></li>
                            </ul>
                        </div>

                        <div>
                            <ul id='asp-trf'>
                                <div><button className='asp-btn' onClick={() => { this.openAspSub(1) }}>TRIBUNAIS REGIONAIS FEDERAIS</button></div>
                                <li id='trf-0'><a href='https://www.trf1.jus.br/' target='_blank' rel='noopener noreferrer'>1ª REGIÃO</a></li>
                                <li id='trf-1'><a href='https://www.trf2.jus.br/' target='_blank' rel='noopener noreferrer'>2ª REGIÃO</a></li>
                                <li id='trf-2'><a href='https://www.trf3.jus.br/' target='_blank' rel='noopener noreferrer'>3ª REGIÃO</a></li>
                                <li id='trf-3'><a href='https://www.trf4.jus.br/' target='_blank' rel='noopener noreferrer'>4ª REGIÃO</a></li>
                                <li id='trf-4'><a href='https://www.trf5.jus.br/' target='_blank' rel='noopener noreferrer'>5ª REGIÃO</a></li>
                            </ul>
                        </div>

                        <div>
                            <ul id='asp-ts'>
                                <div><button className='asp-btn' onClick={() => { this.openAspSub(2) }}>TRIBUNAIS SUPERIORES</button></div>
                                <li id='ts-0'><a href='http://www.stf.jus.br/' target='_blank' rel='noopener noreferrer'>SUPREMO TRIBUNAL FEDERAL</a></li>
                                <li id='ts-1'><a href='http://www.stj.jus.br/' target='_blank' rel='noopener noreferrer'>SUPERIOR TRIBUNAL DE JUSTIÇA</a></li>
                                <li id='ts-2'><a href='http://www.stm.jus.br/' target='_blank' rel='noopener noreferrer'>SUPERIOR TRIBUNAL MILITAR</a></li>
                                <li id='ts-3'><a href='http://www.tst.jus.br/' target='_blank' rel='noopener noreferrer'>TRIBUNAL SUPERIOR DO TRABALHO</a></li>
                                <li id='ts-4'><a href='http://www.tse.jus.br/' target='_blank' rel='noopener noreferrer'>TRIBUNAL SUPERIOR ELEITORAL</a></li>
                            </ul>
                        </div>

                    </div>
                </div>

                <Footer/>

            </div>
        )
    }
}

export default AcompanheSeuProcesso;
