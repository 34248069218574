import React, { useState, Component } from 'react';
import './CSS/Map.css';

import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from 'react-google-maps';
import * as mapData from './DATA/MapData.json';

import GmIcon from '../Assets/gm-icon.svg';
import ImIcon from '../Assets/im-icon.svg';

function GMap() {
    const [selectedStore, setSelectedStore] = useState(null);

    const [geoSelected, setGeoSelected] = useState(null);
    const [myLat, setMyLat] = useState(-8.0864409);
    const [myLong, setMyLong] = useState(-34.8944853);

    navigator.geolocation.getCurrentPosition(function(position) {
        setMyLat(position.coords.latitude);
        setMyLong(position.coords.longitude);
    });

    const geo = {
        lat: myLat,
        lng: myLong
    }

    return (
        <GoogleMap
            defaultZoom={10}
            defaultCenter={{ lat: -8.0864409, lng: -34.8944853}}
            center={{lat: -8.0864409, lng: -34.8944853}}
        >
            {mapData.stores.map((store) => (
                <Marker
                    key={store.id}
                    position={{lat: parseFloat(store.coordinates[0]), lng: parseFloat(store.coordinates[1])}}
                    icon={{
                        url: GmIcon,
                        scaledSize: new window.google.maps.Size(50, 50)
                    }}
                    onClick={() => {
                        setSelectedStore(store);
                    }} 
                >
                    {selectedStore && (
                        <InfoWindow
                            onCloseClick={() => {setSelectedStore(null);}}
                        >
                            
                            <div>
                                <h3>{selectedStore.name}</h3>
                                <p>Endereço: {selectedStore.address}</p>
                                <p>CEP: {selectedStore.cep}</p>
                                <p>Fone: {selectedStore.tel}</p>
                                <p>E-mail: {selectedStore.email}</p>
                            </div>

                        </InfoWindow>
                    )}
                </Marker>
            ))}

            {parseFloat(geo.lat)!= -8.0864409 && parseFloat(geo.lng) != -34.8944853 && (
                <Marker
                    position={{lat: parseFloat(geo.lat), lng: parseFloat(geo.lng)}}
                    icon={{
                        url: ImIcon,
                        scaledSize: new window.google.maps.Size(70, 70)
                    }}
                    onClick={() => {setGeoSelected(geo)}}
                    >
                        {geoSelected && (
                            <InfoWindow
                                onCloseClick={() => {setGeoSelected(null)}}
                            >
                                
                                <div>
                                    <h3>Sua localização!</h3>
                                </div>

                            </InfoWindow>
                        )}
                </Marker>
            )}
        </GoogleMap>
    )
}

const WrapperMap = withScriptjs(withGoogleMap(GMap));

export class Map extends Component {
    render() {
        return (
            <div>
                <WrapperMap
                    isMarkerShown
                    googleMapURL= {`https://maps.googleapis.com/maps/api/js?key=AIzaSyDBCbmbf4LPntaCwK6vrkxMFStgqbHuVEM&v=3.exp&libraries=geometry,drawing,places`}
                    loadingElement= {<div style={{ height: `100%` }} />}
                    containerElement= {<div className='map' />}
                    mapElement= {<div style={{ height: `100%` }} />}
                />
            </div>
        )
    }
}

export default Map;