import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Home from './Pages/Home';
import AcompanheSeuProcesso from './Pages/AcompanheSeuProcesso';
import Jurisprudencias from './Pages/Jurisprudencias';
import LinksUteis from './Pages/LinksUteis';

export class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/acompanhe-seu-processo' component={AcompanheSeuProcesso} />
            <Route exact path='/jurisprudencias' component={Jurisprudencias} />
            <Route exact path='/links-uteis' component={LinksUteis} />
          </Switch>
        </Router>
      </div>
    )
  }
}

export default App;

