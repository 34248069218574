import React, { Component } from 'react';
import './CSS/Home.css';

import Header from '../Components/Header';
import Carousel from '../Components/Carousel';
import InstaFeed from '../Components/InstaFeed';
import Map from '../Components/Map';
import Footer from '../Components/Footer';

import Phone from '../Assets/phone.png';
import Whatsapp from '../Assets/wpp.png';
import Email from '../Assets/email.png';
import Local from '../Assets/local.png';
import BvBarra from '../Assets/barra.png';
import ConhecaNos from '../Assets/conheca-nos.png';
// import Acompanhe from '../Assets/acompanhe.png';
import Avatar from '../Assets/avatar.png';
import Jorio from '../Assets/jorio.png';
import Marcio from '../Assets/marcio.png';
import Pedro from '../Assets/pedro.png';
import JorioPai from '../Assets/jorio-pai.png';

import Popup from 'reactjs-popup';
import $ from 'jquery';

export class Home extends Component {

    componentDidMount () {
        $(".conheca-img-link").on("click", function (e) {
            e.preventDefault();
            $("html, body").animate({ scrollTop: $(".presentation").offset().top });
        });

        $(".local-link").on("click", function (e) {
            e.preventDefault();
            $("html, body").animate({ scrollTop: $(".map-holder").offset().top });
        });
    } 

    clickLegislacao = () => {
        window.open('http://www4.planalto.gov.br/legislacao/', '_blank');
    }

    clickJurisprudencias = () => {
        window.location.href = '/#/jurisprudencias';
    }

    clickJuris = () => {
        window.location.href = '/#/jurisprudencias';
    }

    clickLinks = () => {
        window.location.href = '/#/links-uteis';
    }

    render() {
        return (
            <div className='bg'>
                <Header />

                <div className='carousel'><Carousel /></div>

                <div className='contacts-holder'>
                    <div className='contact'>
                        <div className='tel-text'>
                            <img className='contact-icon' src={Phone} alt='Phone' />
                            <Popup
                                trigger={
                                    <span>
                                        Entre em contato conosco
                                    </span>
                                }
                                position='bottom center'
                            >
                                
                                <span className='tel-box'>
                                    (81) 3038-9496
                                </span>

                            </Popup>
                        </div>
                    </div>

                    <div className='contact'>
                        <a href='https://api.whatsapp.com/send?phone=5581993931527' target='_blank' rel='noopener noreferrer'>
                            <img className='contact-icon' src={Whatsapp} alt='Whatsapp' />
                            <span>
                                Clique aqui para falar com nosso atendimento
                            </span>
                        </a>
                    </div>

                    <div className='contact'>
                        <a href='mailto:atendimento@joriovalenca.com.br' target='_blank' rel='noopener noreferrer'>
                            <img className='contact-icon' src={Email} alt='E-mail' />
                            <span>
                                Mande-nos um e-mail:<br/>
                                <span style={{wordBreak: 'break-all'}}>atendimento@joriovalenca.com.br</span>
                            </span>
                        </a>
                    </div>

                    <div className='contact'>
                        <div className='local-link'>
                            <img className='contact-icon' src={Local} alt='Local' />
                            <span>
                                Clique para ver<br/>
                                nossa localização
                            </span>
                        </div>
                    </div>
                </div>

                <div className='bem-vindo'>
                    <div className='bv-title'>SEJA BEM-VINDO(A)!</div>
                    <img className='bv-barra' src={BvBarra} alt='Barra' />
                    <div className='bv-text'>
                        Aqui você conhecerá sempre mais um pouco sobre nossa trajetória<br/>
                        de tradição e eficiência no âmbito da advocacia criminal.
                    </div>
                </div>

                <div className='acompanhe-processo'>
                    <a className='ap-link' href='/#/acompanhe-seu-processo'>
                        <i className='fa fa-files-o ap-icon' />ACOMPANHE SEU PROCESSO AQUI
                    </a>
                </div>

                <div className='conheca'>
                    <div className='conheca-img-link'>
                        <img src={ConhecaNos} alt='Conheça-nos' />
                        <p className='conheca-text'>CONHEÇA-NOS</p>
                    </div>
                    <div className='c-buttons-holder'>
                        <div><button onClick={this.clickLegislacao} className='conheca-btn'>CONHEÇA A LEGISLAÇÃO</button></div>
                        <div><button onClick={this.clickJurisprudencias} className='conheca-btn'>CONHEÇA AS JURISPRUDÊNCIAS</button></div>
                        <div><button onClick={this.clickLinks} className='conheca-btn'>LINKS ÚTEIS</button></div>
                    </div>
                </div>

                <div className='presentation'>
                    <img src={Avatar} alt='Avatar' />
                    <div>
                        Localizado na Zona Sul do Recife-PE e com atendimento por todo Brasil,<br/>
                        temos profissionais multidisciplianares que atuam em todas as áreas do<br/>
                        Direito Penal, oferecendo acompanhamento personalizado em todos os graus<br/>
                        de jurisdição, incluindo as extraordinárias do Superior Tribunal de Justiça e do<br/>
                        Supremo Tribunal Federal. 
                    </div>
                </div>

                <div className='apt-holder'>
                    <div className='apt'>
                        <div>
                            <div className='apt-box'>
                                <img src={Jorio} alt='Jório' />
                                <span>
                                    <p>
                                        JÓRIO VALENÇA CAVALCANTI FILHO<br/>
                                        OAB/PE 20.373
                                    </p>
                                    <ul>
                                        <li>Bacharel em Direito pela Universidade Católica de Pernambuco (UNICAP);</li>
                                        <li>Advogado militante especializado em Direito Penal e Direito Processual Penal;</li>
                                        <li>Professor das disciplinas de Direito Penal e Processo Penal;</li>
                                        <li>Membro da UNACRIM – União dos Advogados Criminalistas.</li>
                                    </ul>
                                </span>
                            </div>
                        </div>

                        <div>
                            <div className='apt-box'>
                                <img src={Marcio} alt='Márcio' />
                                <span>
                                    <p>
                                        MÁRCIO GUERRA BASTO<br/>
                                        OAB/PE 33.453
                                    </p>
                                    <ul>
                                        <li>Bacharel em Direito pela Faculdade Guararapes;</li>
                                        <li>Advogado militante, especializado em Direito Penal e Direito Processual Penal;</li>
                                        <li>Pós-Graduando pela Escola Superior de Advocacia (ESA).</li>
                                    </ul>
                                </span>
                            </div>
                        </div>

                        <div>
                            <div className='apt-box'>
                                <img src={Pedro} alt='Pedro' />
                                <span>
                                    <p>
                                        PEDRO PETRUCCELLI DE LIMA VALENÇA<br/>
                                        OAB/PE 53.762
                                    </p>
                                    <ul>
                                        <li>Bacharel em Direito pela Universidade Católica de Pernambuco (UNICAP);</li>
                                        <li>Advogado militante especializado em Direito Penal e Processo Penal;</li>
                                        <li>Membro do Tribunal de Justiça Desportiva de Pernambuco.</li>
                                    </ul>
                                </span>
                            </div>
                        </div>

                        <div>
                            <div className='apt-box'>
                                <img src={JorioPai} alt='Jório Pai' />
                                <span>
                                    <p>
                                        JÓRIO VALENÇA CAVALCANTI<br/>
                                        OAB/PE 1.610
                                    </p>
                                    <ul>
                                        <li>Bacharel em Direito pela Universidade Federal de Pernambuco;</li>
                                        <li>Procurador do Estado de Pernambuco, aposentado;</li>
                                        <li>Ex-secretário de Assuntos Jurídicos e Administrativos da Prefeitura da Cidade do Recife;</li>
                                        <li>Advogado militante, especializado em Direito Penal e Direito Processual Penal;</li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='areas-title'>ÁREAS DE ATUAÇÃO:</div>
                <div className='areas-holder'>
                    <div>
                        <ul>
                            <li>Crimes Ambientais</li>
                            <li>Crimes Concorrenciais</li>
                            <li>Crimes Contra a Paz Pública</li>
                            <li>Crimes Contra a Administração Pública</li>
                            <li>Crimes Contra a Dignidade Sexual</li>
                            <li>Crimes Contra a Família</li>
                        </ul>

                        <ul>
                            <li>Crimes Contra a Fé Pública</li>
                            <li>Crimes Contra a Incolumidade Pública</li>
                            <li>Crimes Contra a Ordem Cambiária</li>
                            <li>Crimes Contra a Ordem Econômica e Tributária</li>
                            <li>Crimes Contra a Organização do Trabalho</li>
                            <li>Crimes Contra a Pessoa</li>
                        </ul>

                        <ul>
                            <li>Crimes Contra a Propriedade Imaterial</li>
                            <li>Crimes Contra as Relações de Consumo</li>
                            <li>Crimes Contra o Consumidor</li>
                            <li>Crimes Contra o Patrimônio</li>
                            <li>Crimes Contra o Sentimento Religioso e Contra o Respeito aos Mortos</li>
                        </ul>

                        <ul>
                            <li>Crimes Contra o Sistema Financeiro Nacional</li>
                            <li>Crimes Falimentares</li>
                            <li>Crimes Informáticos</li>
                            <li>Crimes Licitatórios</li>
                            <li>Crimes Praticados por Funcionários e Gestores Públicos</li>
                            <li>Lavagem de Capitais</li>
                        </ul>
                    </div>
                </div>

                <br /><br />
                <div className='map-holder'><Map /></div>

                <Footer />
            </div>
        )
    }
}

export default Home;
